import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { storageConfig } from '../../configs/storage.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   *Creates an instance of AuthService.
   * @memberof AuthService
   */
  constructor(private http: HttpClient) { }


  /**
   * Check if user is authenticated.
   *
   * @returns {boolean}
   * @memberof AuthService
   */
  public isAuthenticated(): boolean {
    // get token from local storage
    let token = localStorage.getItem(storageConfig.authKey);
    // Check whether the token is expired and return
    // true or false
    if (token) {
      let data = JSON.parse(token);
      if (this.isAccessTokenActive() === false) {
        localStorage.removeItem(storageConfig.authKey);
      } else {
        return true;
      }
    }

    return false;
  }


  /**
   * Check if access token is active.
   *
   * @returns
   * @memberof AuthService
   */
  isAccessTokenActive(): boolean {
    // get token from local storage
    let token = localStorage.getItem(storageConfig.authKey);
    // Check whether the token is expired and return
    // true or false
    if (token) {
      let data = JSON.parse(token);
      if (Math.floor(new Date().getTime() / 1000) > data.expires_at) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }


  /**
   * Get the acces token from local storage.
   *
   * @returns {(string|null)}
   * @memberof AuthService
   */
  getAccessToken(): string | null {
    let auth = localStorage.getItem(storageConfig.authKey);
    if (auth) {
      let data = JSON.parse(auth);
      return data.access_token;
    }

    return null;
  }


  /**
   * Get the refresh token from local storage.
   *
   * @returns {(string|null)}
   * @memberof AuthService
   */
  getRefreshToken(): string | null {
    let auth = localStorage.getItem(storageConfig.authKey);
    if (auth) {
      let data = JSON.parse(auth);
      return data.refresh_token;
    }

    return null;
  }


  /**
   * Clear the auth data from storage
   *
   * @memberof AuthService
   */
  clearAuthData() {
    localStorage.removeItem(storageConfig.authKey);
    localStorage.clear();
  }

  /**
   * Get user in session
   */
  getUser(): any {
    let user: string = localStorage.getItem(storageConfig.userKey);

    if (user) {
      user = JSON.parse(user);
    }

    return user;
  }


  /**
   * Store session in storage
   *
   * @param {*} session
   * @memberof AuthService
   */
  setSession(session): void {
    localStorage.setItem(storageConfig.authKey, JSON.stringify(session.data.attributes));
    localStorage.setItem(storageConfig.userKey, JSON.stringify(session.data.include.user));
  }


  /**
  * Login.
  *
  * @param {*} params
  * @returns {Observable<any>}
  * @memberof LoginService
  */
  login(params): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/login-by-code?include=user`, params);
  }


  /**
   * Refresh token
   *
   * @returns
   * @memberof AuthService
   */
  refreshToken() {
    return this.http.post(`${environment.apiUrl}/auth/refresh`, {
      refresh_token: this.getRefreshToken()
    });
  }


  /**
   * Logout service.
   *
   * @returns
   * @memberof AuthService
   */
  logout() {
    // Get the access
    let accessToken = this.getAccessToken();
    // Clear the auth data
    this.clearAuthData();

    return this.http.post(`${environment.apiUrl}/auth/logout`, {
      access_token: accessToken
    });
  }

  /**
   * Pre register a new account.
   * 
   * @param data Account data
   */
  register(data: any) {
    return this.http.post(`${environment.apiUrl}/auth/pre-register`, data);
  }

  /**
   * Check email uniquenes
   */
  checkEmailUniqueness(emailAddress) {
    return this.http.get(`${environment.apiUrl}/auth/check-email?email=${emailAddress}`);
  }
}
