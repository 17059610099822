import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { LoadingComponent } from './shared/components/loading/loading.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /**
   * Title.
   *
   * @memberof AppComponent
   */
  title = 'comercial';

  /**
   * Creates an instance of AppComponent.
   * 
   * @param {TranslateService} translate
   * @memberof AppComponent
   */
  constructor(
    private translate: TranslateService,
    private swUpdate: SwUpdate,
    private dialog: MatDialog
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');
    translate.use('es');
  }


  /**
   * On view init.
   *
   * @memberof AppComponent
   */
  ngOnInit(): void {
    this.listentoAppUpdates();
  }

  /**
   * Listen to app updates.
   *
   * @memberof AppComponent
   */
  listentoAppUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.translate.get('Updating application')
          .subscribe(literal => {

            this.dialog.open(LoadingComponent, {
              disableClose: true,
              data: {
                content: literal
              }
            })
              .afterOpened()
              .subscribe(r => {
                setTimeout(function () {
                  // Reload the current page to load the application again
                  window.location.reload();
                }, 2000);
              })
          });
      });
    }
  }
}
